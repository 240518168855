<template>
  <div class="bg_F8F8F8">
    <Header :activeName="activeName" />

    <Join :activeName="activeName" />
    <Bottom />
  </div>
</template>

<script>
import Header from '@/components/header/index';
import Bottom from '@/components/bottom/index';
import Join from '../menu/join';
export default {
  components: {
    Header,
    Join,
    Bottom
  },
  data() {
    return {
      activeName: '加入我们'
    };
  }
};
</script>
